import React, { forwardRef } from "react";
import classNames from "classnames";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Header } from "@tanstack/react-table";
import Cell from "../Cell";
import { getCommonPinningStyles } from "../functions";
import { HeaderCell } from "../HeaderCell";

export const DraggableRow = SortableElement((props: any) => {
  const { row, rowIndex, index, ...rest } = props;

  const cellsCount = row.getVisibleCells().length;

  return (
    // connect row ref to dnd-kit, apply important styles
    <tr {...rest}>
      {row.getVisibleCells().map((cell: any, cellIndex: any) => (
        <td
          className={classNames("tasks-table__td", {
            "tasks-table__td--in-draggable-column":
              cellIndex > 1 && cellIndex < cellsCount - 3,
          })}
          style={{ ...getCommonPinningStyles(cell.column) }}
        >
          {cell.column.id === "filler" ? null : (
            <Cell
              key={cell.id}
              cell={cell}
              rowData={cell.row.original}
              rowId={row.id}
              rowIndex={rowIndex}
            />
          )}
        </td>
      ))}
    </tr>
  );
});

export function VirtuosoTable({ style, ...props }: any) {
  return (
    <table
      {...props}
      style={{
        ...style,
        width: "100%",
        tableLayout: "auto",
        borderCollapse: "collapse",
        borderSpacing: 1,
      }}
    />
  );
}

const DraggableHeaderCell = SortableElement(
  (props: { header: Header<any, any>; tasksLoading: boolean }) => {
    const { header, tasksLoading } = props;
    return (
      <th
        className={classNames("tasks-table__th", "tasks-table__th--draggable")}
        style={{
          ...getCommonPinningStyles(header.column, true),
        }}
      >
        <HeaderCell
          key={header.id}
          header={header}
          columnIndex={header.index}
          tasksLoading={tasksLoading}
          draggable
        />
      </th>
    );
  },
);

const TableHeadRowContext = SortableContainer(({ ...props }: any) => {
  const { headerGroup, tasksLoading } = props;

  return (
    <tr className="tasks-table__column-dragging-context" key="header-group">
      {headerGroup.headers.slice(0, 3).map((header: any) => {
        return (
          <th
            key={header.id}
            className="tasks-table__th"
            style={{
              ...getCommonPinningStyles(header.column),
            }}
          >
            <HeaderCell
              header={header}
              columnIndex={header.index}
              tasksLoading={tasksLoading}
            />
          </th>
        );
      })}

      {headerGroup.headers
        .slice(3, headerGroup.headers.length)
        .map((header: any, index: number) => {
          if (index + 4 >= headerGroup.headers.length) {
            return (
              <th
                key={header.id}
                className="tasks-table__th"
                style={{
                  ...getCommonPinningStyles(header.column),
                }}
              >
                <HeaderCell
                  header={header}
                  columnIndex={header.index}
                  tasksLoading={tasksLoading}
                />
              </th>
            );
          }

          return (
            <DraggableHeaderCell
              key={`header-${header.id}`}
              index={index}
              header={header}
              tasksLoading={tasksLoading}
            />
          );
        })}
    </tr>
  );
});

export const VirtuosoTableHead = forwardRef((props: any, ref: any) => {
  const { context, style, ...rest } = props;

  const { handleColumnDragEnd, setIsDragging, table, tasksLoading } = context;

  const headerGroup = table.getHeaderGroups()[0];

  return (
    <thead ref={ref} style={{ ...style, top: 74 }} {...rest}>
      <TableHeadRowContext
        axis="x"
        lockAxis="x"
        helperClass="tasks-table-column-dragged-item"
        helperContainer={
          (document.querySelector(
            ".tasks-table__column-dragging-context",
          ) as HTMLElement) || undefined
        }
        onSortStart={() => setIsDragging(true)}
        onSortEnd={handleColumnDragEnd}
        headerGroup={headerGroup}
        tasksLoading={tasksLoading}
        useDragHandle
      />
    </thead>
  );
});

const TableBodyContext = SortableContainer(({ listRef, ...props }: any) => {
  return <tbody ref={listRef} {...props} />;
});

export const VirtuosoTableBody = forwardRef((props: any, ref: any) => {
  const { context, ...rest } = props;

  const { handleRowDragEnd } = context;

  return (
    <TableBodyContext
      {...rest}
      listRef={ref}
      onSortEnd={handleRowDragEnd}
      useDragHandle
    />
  );
});

export function VirtuosoTableRow(props: any) {
  const { item: row } = props;

  const index = props["data-index"];

  return (
    <DraggableRow
      key={row.id}
      index={index}
      rowIndex={index}
      row={row}
      {...props}
    />
  );
}
