import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import "./TableSearch.scss";

import classNames from "classnames";
import CustomInput from "src/app/components/CustomInput/CustomInput";

interface Props {
  visible: boolean;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const TableSearch: React.FC<Props> = (props) => {
  const { visible, value, setValue } = props;

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (visible) {
      ref.current?.focus();
    }
  }, [visible]);

  return (
    <div
      className={classNames("table-search", {
        "table-search--hidden": !visible,
      })}
    >
      <CustomInput
        ref={ref}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};
