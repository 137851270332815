import React, { useEffect, useState } from "react";
import "./ToolbarList.scss";

import classNames from "classnames";

import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SUPPORTED_SHOWCASE_SOCIAL_MEDIA } from "src/app/CreatorShowcase/CreatorShowcase";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import { translateMessage } from "src/app/methods/translateMessage";
import { ReactComponent as ChevronDown } from "src/images/chevron-down.svg";
import {
  setNewLayout,
  setReportGridElements,
  setReportHiddenGridElements,
  setReportLayout,
} from "../../../../redux";
import { SocialProvider } from "../../../../types";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { addElementToLayout } from "../../functions/addElementToLayout";
import { METRIC_FIELDS } from "../../utils/variables";
import { ToolbarListCreatorMetaFields } from "../ToolbarListCreatorMetafields/ToolbarListCreatorMetaFields";
import { ToolbarListItem } from "../ToolbarListItem/ToolbarListItem";
import { ToolbarListPublicationMetaFields } from "../ToolbarListPublicationMetaFields/ToolbarListPublicationMetaFields";

export const TIK_TOK_BLACKLIST = ["credibility", "location-by-city"];
export const YOUTUBE_BLACKLIST = [
  "credibility",
  "location-by-city",
  "keywords",
  "creators-brand-affinity",
  "audience-interests",
  "audience-brand-affinity",
  "creator-interests",
];
export const SNAPCHAT_WHITELIST = [
  "avg-views",
  "bio",
  "followers",
  "recently-published",
];

export const FACEBOOK_WHITELIST = ["bio", "followers"];

function ToolbarList(props) {
  const {
    provider,
    format,
    open,
    toolbarListProps,
    disableExpanding,
    isReport,
    intl,
    dynamicProvider,
  } = props;

  const { gridElements, hiddenGridElements } = toolbarListProps;

  const [showList, setShowList] = useState(open);
  const [sectionVisible, setSectionVisible] = useState(false);
  const [listElements, setListElements] = useState([]);

  const {
    reportReducer: { layout },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const renderHeader = () => {
    if (dynamicProvider) return provider;

    switch (provider) {
      case "metadata":
        if (isReport) {
          return translateMessage({
            intl,
            id: "ws_campaign_details",
            defaultMessage: "Campaign details",
          });
        }
        return translateMessage({
          intl,
          id: "ws_all",
          defaultMessage: "All",
        });

      case SocialProvider.Instagram:
        return "Instagram";

      case SocialProvider.Youtube:
        return "Youtube";

      case SocialProvider.TikTok:
        return "TikTok";

      case SocialProvider.Snapchat:
        return "Snapchat";

      case SocialProvider.Twitter:
        return "X";
      case SocialProvider.Facebook:
        return "Facebook";

      case "creator-insights":
        return translateMessage({
          intl,
          id: "ws_creator_insights",
          defaultMessage: "Creator Insights",
        });

      case "audience-data":
        return translateMessage({
          intl,
          id: "ws_campaign_audience_data",
          defaultMessage: "Campaign Audience Data",
        });

      case "publication":
        return translateMessage({
          intl,
          id: "ws_all_publications",
          defaultMessage: "All Publications",
        });

      case "post-data":
        return translateMessage({
          intl,
          id: "ws_executive_summary",
          defaultMessage: "Executive Summary",
        });

      case "creator":
        return translateMessage({
          intl,
          id: "ws_creators_in_the_campaign",
          defaultMessage: "Creators in the Campaign",
        });

      case "comments-analysis":
        return translateMessage({
          intl,
          id: "ws_comments_analysis",
          defaultMessage: "Comments analysis",
        });
    }
  };

  const getSectionId = () => {
    switch (provider) {
      case "metadata":
        return "#subheader-metadata";

      case "creator-insights":
        return "#subheader-creator-insights";

      case "audience-data":
        return "#subheader-audience-data";

      case "publication":
        return "#header-publication";

      case "post-data":
        return "#subheader-post-data";

      case "creator":
        return "#subheader-creator";

      case "comments-analysis":
        return "#header-comments-analysis";
    }
  };

  const getGridElementsByType = (type, label) => {
    const newGridElements = gridElements
      .filter((item) => {
        if (dynamicProvider) return item.section === type;
        return item.gridBoxType === type;
      })
      .map((item) => {
        return {
          label: item[label],
          value: item.label,
        };
      });

    const newHiddenElements = hiddenGridElements
      .filter((item) => item.gridBoxType === type)
      .map((item) => {
        return {
          label: item[label],
          value: item.label,
        };
      });

    return [...newGridElements, ...newHiddenElements];
  };

  const getFilteredFields = () => {
    if (dynamicProvider) return getGridElementsByType(dynamicProvider, "title");

    switch (provider) {
      case "metadata":
        return getGridElementsByType("metadata", "name");

      case SocialProvider.Instagram:
      case SocialProvider.Twitter:
        return METRIC_FIELDS;

      case SocialProvider.TikTok:
        return METRIC_FIELDS.filter(
          (item) => !TIK_TOK_BLACKLIST.includes(item.value),
        );

      case SocialProvider.Youtube:
        return METRIC_FIELDS.filter(
          (item) => !YOUTUBE_BLACKLIST.includes(item.value),
        );
      case SocialProvider.Snapchat:
        return METRIC_FIELDS.filter((item) =>
          SNAPCHAT_WHITELIST.includes(item.value),
        );
      case SocialProvider.Facebook:
        return METRIC_FIELDS.filter((item) =>
          FACEBOOK_WHITELIST.includes(item.value),
        );
      case "creator-insights":
        return getGridElementsByType("creator-insights", "name");

      case "audience-data":
        return getGridElementsByType("audience-data", "name");

      case "post-data":
        return getGridElementsByType("post-data", "name");

      case "creator":
        return getGridElementsByType("creator", "title");

      case "publication":
        return getGridElementsByType("publication", "title");

      case "comments-analysis":
        const sentiments = getGridElementsByType("sentiment-analysis", "name");
        const comments = getGridElementsByType("comments", "name");
        return [...sentiments, ...comments];

      default:
        return [];
    }
  };

  const toggleSectionVisibility = () => {
    if (sectionVisible) {
      hideSection();
    } else {
      showSection();
    }
  };

  const showSection = async () => {
    const newHiddenGridElements = hiddenGridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section !== dynamicProvider &&
          item.label !== `header-${dynamicProvider}` &&
          item.label !== `subheader-${dynamicProvider}`
        );
      }

      return (
        item.gridBoxType !== provider &&
        item.label !== `header-${provider}` &&
        item.label !== `subheader-${provider}`
      );
    });

    const gridElementsToAdd = hiddenGridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section === dynamicProvider ||
          item.label === `header-${dynamicProvider}` ||
          item.label === `subheader-${dynamicProvider}`
        );
      }

      return (
        item.gridBoxType === provider ||
        item.label === `header-${provider}` ||
        item.label === `subheader-${provider}`
      );
    });

    dispatch(setReportGridElements([...gridElements, ...gridElementsToAdd]));
    dispatch(setReportHiddenGridElements(newHiddenGridElements));

    const newLayout = [...layout];

    const setLayout = isReport ? setReportLayout : setNewLayout;

    const publicationsVisible = newLayout.find((item) =>
      item.i.includes("publication"),
    );

    if (dynamicProvider && publicationsVisible) {
      const newDynamicLayout = await addDynamicSectionToLayout({
        gridElementsToAdd,
        newLayout,
        setLayout,
      });
      await dispatch(setLayout(newDynamicLayout));
    } else {
      await addSectionToLayout({ gridElementsToAdd, newLayout, setLayout });
      await dispatch(setLayout(newLayout));
    }

    if (dynamicProvider) {
      const dynamicHeader = document.getElementById(
        `subheader-${dynamicProvider}`,
      );
      if (dynamicHeader) dynamicHeader.scrollIntoView();
      return;
    }

    if (provider === "creator") {
      const creatorHeader = document.getElementById("subheader-creator");
      creatorHeader.scrollIntoView();
      return;
    }

    if (provider === "publication-") {
      const publicationHeader = document.getElementById("header-publication");
      publicationHeader.scrollIntoView();
      return;
    }

    const generator = document.querySelector(".template-generator");
    const scrollDiff = isReport ? -3500 : 0;
    generator.scroll({
      top: generator.clientHeight + generator.scrollHeight + scrollDiff,
      left: 0,
      behavior: "smooth",
    });
  };

  const addSectionToLayout = (props) => {
    const { gridElementsToAdd, newLayout, setLayout } = props;

    for (let i = 0; i < gridElementsToAdd.length; i++) {
      addElementToLayout(
        gridElementsToAdd[i].label,
        newLayout,
        dispatch,
        gridElementsToAdd[i].gridBoxType,
        format,
        setLayout,
        hiddenGridElements,
        isReport,
        true,
      );
    }
  };

  const addDynamicSectionToLayout = (props) => {
    const { gridElementsToAdd, newLayout, setLayout } = props;

    const filteredLayout = newLayout.filter(
      (item) =>
        item.i.slice(0, 11) === "publication" ||
        item.i.slice(0, 15) === "top-publication" ||
        item.i.slice(0, 25) === "subheader-top-publication",
    );
    const { rows } = format;

    const lastElementEndY =
      Math.max(...filteredLayout.map((item) => item.y)) + 7;
    const borderRow = lastElementEndY + (rows - (lastElementEndY % rows));

    const mappedLayout = newLayout.map((item) => {
      if (item.i.includes("separator")) return item;

      if (item.y >= borderRow) {
        return {
          ...item,
          y: item.y + rows,
        };
      }

      return item;
    });

    for (let i = 0; i < gridElementsToAdd.length; i++) {
      addElementToLayout(
        gridElementsToAdd[i].label,
        mappedLayout,
        dispatch,
        gridElementsToAdd[i].gridBoxType,
        format,
        setLayout,
        hiddenGridElements,
        isReport,
        true,
      );
    }

    return mappedLayout;
  };

  const hideSection = () => {
    const newGridElements = gridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section !== dynamicProvider &&
          item.label !== `header-${dynamicProvider}` &&
          item.label !== `subheader-${dynamicProvider}`
        );
      }

      return (
        item.gridBoxType !== provider &&
        item.label !== `header-${provider}` &&
        item.label !== `subheader-${provider}`
      );
    });

    const hiddenGridElementsToAdd = gridElements.filter((item) => {
      if (dynamicProvider) {
        return (
          item.section === dynamicProvider ||
          item.label === `header-${dynamicProvider}` ||
          item.label === `subheader-${dynamicProvider}`
        );
      }

      return (
        item.gridBoxType === provider ||
        item.label === `header-${provider}` ||
        item.label === `subheader-${provider}`
      );
    });

    dispatch(setReportGridElements(newGridElements));
    dispatch(
      setReportHiddenGridElements([
        ...hiddenGridElements,
        ...hiddenGridElementsToAdd,
      ]),
    );
  };

  useEffect(() => {
    setListElements(getFilteredFields());
  }, [gridElements, hiddenGridElements]);

  useEffect(() => {
    if (!listElements.length) return;
    if (
      listElements.every((item) =>
        gridElements?.find((el) => {
          let label = item.value;
          if (SUPPORTED_SHOWCASE_SOCIAL_MEDIA.includes(provider)) {
            label = `${provider}-${label}`;
          }

          return label === el.label;
        }),
      )
    ) {
      setSectionVisible(true);
    } else {
      setSectionVisible(false);
    }
  }, [listElements, gridElements]);

  const hideTooltip = listElements.length || dynamicProvider;

  return (
    <div
      className={classNames("toolbar-list", {
        "toolbar-list--closed": !showList,
        "toolbar-list--dynamic-provider": dynamicProvider,
      })}
    >
      <div className="toolbar-list__content">
        <div className="toolbar-list__item">
          <div className="toolbar-list__item-wrapper">
            <MaterialTooltip
              id={`cannot-add-${provider}-tooltip`}
              center
              content={
                <IDHFormattedMessage
                  id={`ws_report_${provider}_cannot_be_added`}
                  defaultMessage={`Section ${renderHeader()} cannot be added.`}
                />
              }
              contentHidden={hideTooltip}
              clickable={false}
              className="coupon-tooltip"
              contentClassName=""
            >
              <CustomCheckbox
                id={`${provider}-section`}
                checked={sectionVisible}
                onChange={toggleSectionVisibility}
                blue={!dynamicProvider}
                disabled={!hideTooltip}
              />
            </MaterialTooltip>

            {isReport ? (
              <a href={getSectionId()}>{renderHeader()}</a>
            ) : (
              renderHeader()
            )}
          </div>
          {!disableExpanding && listElements.length ? (
            <span
              className={classNames("toolbar-list__item-button", {
                "toolbar-list__item-button--clicked": showList,
              })}
              onClick={() => setShowList((v) => !v)}
            >
              <ChevronDown />
            </span>
          ) : null}
        </div>

        {showList && !disableExpanding && (
          <div className="toolbar-list__list" key={listElements.length}>
            {provider === "creator" ? (
              <ToolbarListCreatorMetaFields />
            ) : provider === "publication" ? (
              <ToolbarListPublicationMetaFields />
            ) : (
              <>
                {listElements
                  .sort((a, b) => a.value.localeCompare(b.value))
                  .map((item) => {
                    return (
                      <ToolbarListItem
                        key={item.value}
                        item={item}
                        provider={provider}
                        format={format}
                        toolbarListProps={toolbarListProps}
                        isReport={isReport}
                      />
                    );
                  })}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default injectIntl(ToolbarList);
