import React from "react";

import { ReactComponent as GoToIcon } from "src/images/share-alt.svg";
import { ReactComponent as InfoIcon } from "src/images/info.svg";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { MaterialTooltip } from "src/app/components/MaterialTooltip/MaterialTooltip";
import OuterLink from "../../components/OuterLink/OuterLink";
import CONSTS from "../../helpers/consts";

export function GoToSocial(props) {
  const { socialProfileUrl, socialProvider } = props;

  const renderCtaText = () => {
    switch (socialProvider) {
      case CONSTS.socialProvider.instagram:
        return (
          <IDHFormattedMessage
            id="front_go_to_instagram_profile"
            defaultMessage="Go to Instagram profile"
          />
        );
      case CONSTS.socialProvider.tiktok:
        return (
          <IDHFormattedMessage
            id="front_go_to_tiktok_profile"
            defaultMessage="Go to Tiktok profile"
          />
        );
      case CONSTS.socialProvider.douyin:
        return (
          <IDHFormattedMessage
            id="front_go_to_douyin_profile"
            defaultMessage="Go to Douyin profile"
          />
        );
      case CONSTS.socialProvider.youtube:
        return (
          <IDHFormattedMessage
            id="front_go_to_youtube_profile"
            defaultMessage="Go to YouTube profile"
          />
        );
      case CONSTS.socialProvider.snapchat:
        return (
          <IDHFormattedMessage
            id="front_go_to_snapchat_profile"
            defaultMessage="Go to Snapchat profile"
          />
        );
      case CONSTS.socialProvider.facebook:
        return (
          <IDHFormattedMessage
            id="front_go_to_facebook_profile"
            defaultMessage="Go to Facebook profile"
          />
        );
      default:
        return null;
    }
  };

  const renderCtaTooltip = () => {
    switch (socialProvider) {
      case CONSTS.socialProvider.instagram:
        return (
          <IDHFormattedMessage
            id="front_user_has_disabled_embedding_posts"
            defaultMessage="User has disabled embedding posts"
          />
        );
      case CONSTS.socialProvider.tiktok:
      case CONSTS.socialProvider.douyin:
      case CONSTS.socialProvider.youtube:
      case CONSTS.socialProvider.snapchat:
      case CONSTS.socialProvider.facebook:
        return (
          <IDHFormattedMessage
            id="front_preview_unavailable"
            defaultMessage="Preview unavailable"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="audience-results__go-to-social">
      <MaterialTooltip center content={renderCtaTooltip()}>
        <OuterLink href={socialProfileUrl}>
          <div className="audience-results__go-to-social__container">
            <InfoIcon className="audience-results__go-to-social-info" />
            {renderCtaText()}
            <GoToIcon className="audience-results__go-to-social-icon" />
          </div>
        </OuterLink>
      </MaterialTooltip>
    </div>
  );
}
