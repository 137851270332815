import React, { useEffect, useMemo, useRef, useState } from "react";

import RGL, { WidthProvider } from "react-grid-layout";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import "./ShowcaseTemplate.scss";

import { addMetricsToLayout } from "./addMetricsToLayout";
import { renderMetrics } from "./renderMetrics";

import { BasicInfo } from "./components/BasicInfo/BasicInfo";
import {
  setMultipleLayouts,
  setNewGridElements,
  setNewHiddenGridElements,
  setNewLayout,
  setNewSavedTemplate,
  setTemplateBase,
} from "../../../../redux";

import { ReactComponent as InstagramIcon } from "../../../../images/social/instagram-color.svg";
import { ReactComponent as TikTokIcon } from "../../../../images/social/tiktok-color.svg";
import { ReactComponent as YoutubeIcon } from "../../../../images/social/youtube-color.svg";
import { ReactComponent as SnapchatIcon } from "../../../../images/social/snapchat.svg";
import { ReactComponent as XIcon } from "../../../../images/social/x.svg";
import { ReactComponent as FacebookIcon } from "../../../../images/social/facebook-color.svg";
import CampaignPlaceholder from "../../../../images/project-placeholder.svg";
import { ShowcaseLoader } from "../ShowcaseLoader/ShowcaseLoader";
import { ShowcasePrintingLoader } from "../ShowcasePrintingLoader/ShowcasePrintingLoader";
import { usePrevious } from "../../../methods/usePrevious";
import { applySystemTemplate } from "./applySystemTemplate";
import { ShowcaseGridBox } from "./components/ShowcaseGridBox/ShowcaseGridBox";
import { renderMetaField } from "../../../generator-data/functions/renderMetaField";
import { addMetaFieldToLayout } from "../../../generator-data/functions/addMetaFieldToLayout";
import { getGeneratorGridElements } from "../../../generator-data/functions/getGeneratorGridElements";
import { addSeparatorsToLayout } from "../../../generator-data/functions/addSeparatorsToLayout";
import { METRIC_FIELDS } from "../../../generator-data/utils/variables";
import { getImageSource } from "../../../../utils/methods";
import { SocialProvider } from "../../../../types";
import { getShowcaseSettingByKey } from "../../utils";

const ReactGridLayout = WidthProvider(RGL);

export const STATIC_ELEMENTS = ["basic-info", "separator"];

function ShowcaseTemplate({
  data,
  filteredMetadata,
  format,
  pages,
  setPages,
  metricsData,
  progressValue,
  progressTarget,
  templateApplied,
  setTemplateApplied,
  recentlyPublishedReady,
  setRecentlyPublishedReady,
}) {
  const templateRef = useRef();

  const initialLayout = [
    { i: "basic-info", x: 0, y: 0, w: 6, h: 1, static: true },
  ];

  const [separators, setSeparators] = useState([]);
  const [shouldRerenderLayout, setShouldRerenderLayout] = useState(true);
  const [hiddenFields, setHiddenFields] = useState(
    METRIC_FIELDS.filter((item) => item.initialHidden) || [],
  );

  const dispatch = useDispatch();
  const generating = useSelector((state) => state.showcaseReducer.generating);

  const layout = useSelector((state) => state.showcaseReducer.layout);

  const savedTemplate = useSelector(
    (state) => state.showcaseReducer.savedTemplate,
  );
  const gridElements = useSelector(
    (state) => state.showcaseReducer.gridElements,
  );
  const hiddenGridElements = useSelector(
    (state) => state.showcaseReducer.hiddenGridElements,
  );
  const printing = useSelector((state) => state.showcaseReducer.printing);
  const multipleLayouts = useSelector(
    (state) => state.showcaseReducer.multipleLayouts,
  );
  const selectedTemplate = useSelector(
    (state) => state.showcaseReducer.selectedTemplate,
  );
  const showcaseTransition = useSelector(
    (state) => state.showcaseReducer.showcaseTransition,
  );

  const setLayout = (newLayout) => {
    dispatch(setNewLayout(newLayout));
  };

  const setGridElements = (newGridElements) => {
    dispatch(setNewGridElements(newGridElements));
  };

  const setHiddenGridElements = (newHiddenGridElements) => {
    dispatch(setNewHiddenGridElements(newHiddenGridElements));
  };

  const creatorId = getShowcaseSettingByKey("creatorId");
  const previousCreatorId = usePrevious(creatorId);

  // saving layout for active creators
  useEffect(() => {
    if (!layout.length) return;

    const newMultipleLayouts = multipleLayouts.map((item) => {
      if (item.creatorId === previousCreatorId) {
        return {
          ...item,
          layout,
        };
      }

      return item;
    });

    dispatch(setMultipleLayouts(newMultipleLayouts));
    setHiddenFields(METRIC_FIELDS.filter((item) => item.initialHidden) || []);
  }, [creatorId]);

  useEffect(() => {
    if (savedTemplate.length) {
      setTemplateApplied(false);
    }
  }, [savedTemplate]);

  const getGridElements = () => {
    if (data?.id !== creatorId) return;

    const functionProps = {
      data,
      filteredMetadata,
      hiddenFields,
      metricsData,
      savedTemplate,
      templateApplied,
      //
      setGridElements,
      setHiddenFields,
      setHiddenGridElements,
      setShouldRerenderLayout,
    };

    getGeneratorGridElements(functionProps);
  };

  useEffect(() => {
    if (!templateApplied) {
      getGridElements();
    }
  }, [templateApplied]);

  useEffect(() => {
    getGridElements();
  }, [filteredMetadata, metricsData, format]);

  useEffect(() => {
    if (selectedTemplate.value.type === "default" && !printing) {
      getGridElements();
    }
  }, [selectedTemplate]);

  // Rendering

  const applySavedLayout = () => {
    setLayout(savedTemplate);
    dispatch(setTemplateBase(savedTemplate));
    dispatch(setNewSavedTemplate([]));
    setTemplateApplied(true);
  };

  const applyChosenTemplate = () => {
    const newLayout = initialLayout;
    const newSeparators = [];

    gridElements
      .filter((item) => item.gridBoxType === "metadata")
      .forEach((box) => {
        addMetaFieldToLayout(box, newLayout, newSeparators, format);
      });

    applySystemTemplate(
      newLayout,
      format,
      selectedTemplate.value.layout,
      gridElements,
    );

    setLayout(newLayout);
    setSeparators(newSeparators);

    const allElements = [...gridElements, ...hiddenGridElements];

    const newGridElements = allElements.filter((item) => {
      return newLayout.find((i) => i.i === item.label);
    });

    const newHiddenGridElements = allElements.filter((item) => {
      return !newLayout.find((i) => i.i === item.label);
    });

    if (gridElements.length !== newGridElements.length) {
      setGridElements(newGridElements);
      setHiddenGridElements(newHiddenGridElements);
    }
  };

  const applyDefaultLayout = () => {
    const newLayout = initialLayout;
    const newSeparators = [];

    gridElements
      .filter((item) => item.gridBoxType === "metadata")
      .forEach((box) => {
        addMetaFieldToLayout(box, newLayout, newSeparators, format);
      });

    metricsData.forEach((metric) => {
      addMetricsToLayout(metric, newLayout, format);
    });

    setLayout(newLayout);
    setSeparators(newSeparators);
  };

  useEffect(() => {
    if (!shouldRerenderLayout) return;
    // applying saved template
    if (savedTemplate?.length && !templateApplied) {
      applySavedLayout();
    }
    // applying default template
    else if (selectedTemplate?.value?.type === "default") {
      applyChosenTemplate();
    } else {
      applyDefaultLayout();
    }
    setShouldRerenderLayout(false);
  }, [gridElements, format.pageWidth]);

  useEffect(() => {
    if (!layout?.length) return;

    const functionProps = {
      format,
      gridElements,
      layout,
      pages,
      separators,
      //
      setPages,
      setLayout,
      setSeparators,
    };

    addSeparatorsToLayout(functionProps);
  }, [layout]);

  const ShowcaseBoxes = useMemo(() => {
    // if (!data?.socialProfiles) return;

    return gridElements.map((box) => {
      switch (box.gridBoxType) {
        case "metadata":
          return renderMetaField(box, data, ShowcaseGridBox);
        case SocialProvider.Instagram:
        case SocialProvider.TikTok:
        case SocialProvider.Youtube:
        case SocialProvider.Snapchat:
        case SocialProvider.Twitter:
        case SocialProvider.Facebook:
          return renderMetrics(
            box,
            data,
            layout,
            recentlyPublishedReady,
            setRecentlyPublishedReady,
          );
      }
    });
  }, [
    gridElements,
    layout,
    format,
    data,
    recentlyPublishedReady,
    setRecentlyPublishedReady,
  ]);

  const renderProviderIcon = (provider) => {
    switch (provider) {
      case SocialProvider.Instagram:
        return <InstagramIcon />;
      case SocialProvider.TikTok:
        return <TikTokIcon />;
      case SocialProvider.Youtube:
        return <YoutubeIcon />;
      case SocialProvider.Snapchat:
        return <SnapchatIcon />;
      case SocialProvider.Twitter:
        return <XIcon />;
      case SocialProvider.Facebook:
        return <FacebookIcon />;
    }
  };

  const onLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const filteredLayout = layout.filter(
    (item) =>
      gridElements.find((box) => item.i === box.label) ||
      STATIC_ELEMENTS.some((el) => item.i.includes(el)),
  );

  return (
    <div
      ref={templateRef}
      className={classNames("showcase-template", {
        "showcase-template--hidden": showcaseTransition,
      })}
      style={{
        padding: `${format.rowHeight / 2}px 0`,
        width: `${format.pageWidth}px`,
      }}
    >
      {printing ? (
        <ShowcasePrintingLoader value={progressValue} target={progressTarget} />
      ) : null}

      {generating ? (
        <ShowcaseLoader />
      ) : (
        <ReactGridLayout
          style={{ width: `${format.pageWidth}px` }}
          className="layout"
          layout={filteredLayout}
          cols={format.cols}
          rowHeight={format.rowHeight}
          compactType={null}
          margin={[24, 20]}
          preventCollision
          onLayoutChange={onLayoutChange}
          draggableHandle=".react-drag-handle"
        >
          <ShowcaseGridBox
            id="basic-info"
            key="basic-info"
            className="basic-info-wrapper"
          >
            <BasicInfo data={data} />
          </ShowcaseGridBox>

          {ShowcaseBoxes}

          {separators.map((item) => {
            const campaignSrc = getImageSource(
              data?.wsProjectCover,
              "tiny",
              CampaignPlaceholder,
            );

            return (
              <div
                id={item.key}
                key={item.key}
                className="showcase-template__separator"
              >
                <div
                  className={classNames("showcase-template__separator-line", {
                    "showcase-template__separator-line--hidden": printing,
                  })}
                />
                {item.provider !== "last" ? (
                  <>
                    <div className="showcase-template__separator-provider">
                      {renderProviderIcon(item.provider)}
                      {data.name}
                    </div>
                    <div className="showcase-template__separator-campaign-logo">
                      <img
                        src={!printing ? campaignSrc : undefined}
                        data-img-src={printing ? campaignSrc : undefined}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                  </>
                ) : null}
              </div>
            );
          })}
        </ReactGridLayout>
      )}
    </div>
  );
}

export default ShowcaseTemplate;
